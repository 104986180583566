import { Props } from "lib/data";
import { useEffect, useRef, useState } from "react";
import useAppStore from "store/appstore";

const TemplateVideo: React.FC<Props> = ({ mediaURL }) => {
  const changePage = useAppStore((state) => state.changePage);
  const [cachedURL, setCachedURL] = useState<string | undefined>(undefined);
  let getMediaFromCache = useAppStore((state) => state.getMediaFromCache);
  const ref = useRef<HTMLVideoElement>(null);
  const playMuted = useAppStore((state) => state.playMuted);
  const setPlayMuted = useAppStore((state) => state.setPlayMuted);

  useEffect(() => {
    // setTimeout(changePage, 10000);
  });
  useEffect(() => {
    getMediaFromCache(mediaURL!).then((url) => {
      if (!url) {
        console.log("missing cachedURL");
        setTimeout(changePage, 1000);
      } else {
        console.log(`set cached url ${url}`);
        setCachedURL(url!);
      }
    });
  });
  return (
    <div>
      {cachedURL && (
        <video
          ref={ref}
          src={cachedURL}
          // autoPlay
          playsInline
          muted={playMuted}
          controls={false}
          onError={() => {
            console.log("Media Error");
            changePage();
          }}
          onEnded={() => {
            console.log("Media Ended");
            changePage();
          }}
          onLoadedMetadata={async () => {
            console.log("loadedMetadata");

            ref.current
              ?.play()
              .then((done) => {
                console.log(`Play done ${done}`);
              })
              .catch((error) => {
                console.error("Error playing video");
                console.error(error);
                setPlayMuted(true);
                changePage();
              });
          }}
        />
      )}
    </div>
  );
};
export default TemplateVideo;
