import React from "react";
import reactStringReplace from "react-string-replace";
interface Props {
	text: string;
	textHighlight: string;
}

const SubTitleView: React.FC<Props> = (props) => {
	const { text, textHighlight } = props;

	return (
		<div className="Text-style-subtitle Text-shadow mt-6 ml-[124px]">
			{textHighlight.length > 0
				? reactStringReplace(text, textHighlight, (match, index) => (
						<span key={index} className="Text-yellow">
							{match}
						</span>
				  ))
				: text}
		</div>
	);
};

export default SubTitleView;
