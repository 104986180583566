import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import OddView from "../components/OddView";
import BackgroundView from "../components/BackgroundView";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

const Template9: React.FC<Props> = (props) => {
  const { template } = props;
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);
  return (
    <div>
      <BackgroundView
        background={template.background}
        title={template.betModel.title}
        subTitle={template.betModel.subTitle}
        titleHighlight={template.betModel.titleHighlight}
        subTitleHighlight={template.betModel.subTitleHighlight}
      >
        <div className="relative flex flex-col justify-center text-center w-[1920px] mt-40 tall:mt-96">
          <div className="inline-flex justify-center items-center">
            <div className="block text-center w-[550px]">
              <OddView
                odd={template.betModel.odds!![0]}
                className="Odd-normal"
              />
              <OddView
                odd={template.betModel.odds!![1]}
                className="Odd-normal"
              />
            </div>
            <div className="block text-center w-[550px]">
              <OddView
                odd={template.betModel.odds!![2]}
                className="Odd-normal"
              />
              <OddView
                odd={template.betModel.odds!![3]}
                className="Odd-normal"
              />
            </div>
          </div>
        </div>
      </BackgroundView>
      <template.background.logo className="absolute w-[200px] bottom-[12px] left-0 right-0 m-auto animate-fade-in-up-logo" />
    </div>
  );
};

export default Template9;
