import React from "react";
import { MatchModel } from "../lib/data";
import NewOddView from "./NewOddView";
import NewTimeView from "./NewTimeView";

interface Props {
  match: MatchModel;
  timeBeforeOdds: boolean;
  index?: number;
  className: string;
  suffix?: string;
}

const NewMatchView: React.FC<Props> = (props) => {
  const { match, timeBeforeOdds, index, className, suffix } = props;

  return (
    <div className={`flex  ${className}`}>
      <div className="inline-flex items-center flex-1 Title Text-shadow overflow-hidden">
        <span
          className={`${match.team1TextColor} text-ellipsis overflow-hidden whitespace-nowrap w-full`}
        >
          {match.title ? match.title : `${match.team1} — ${match.team2}`}
        </span>
      </div>

      <div className="inline-flex">
        {match.odds!!.map((odd, index) => {
          return (
            <div
              key={index}
              className={`inline-flex justify-center items-center text-center bg-black ${
                odd.prefix !== undefined ? "min-w-[182px]" : "min-w-[148px]"
              } ml-1 `}
            >
              <NewOddView
                key={index}
                odd={odd}
                className={`${odd.prefix ? "Odd-smallest" : "Odd-small"}`}
              />
            </div>
          );
        })}
      </div>

      <div className="inline-flex items-center justify-center ml-1 w-[120px]">
        <NewTimeView time={match.time!!} />
      </div>

      {/* {suffix !== undefined && (
        <div className="float-left text-center Text-yellow Suffix Text-shadow ml-12">
          {suffix}
        </div>
      )} */}
    </div>
  );
};

export default NewMatchView;
