import React from "react";
import { TeamModel } from "../lib/data";
interface Props {
	team: TeamModel;
}

const TeamView: React.FC<Props> = (props) => {
	const { team } = props;

	return (
		<div className="inline-block w-[100%]">
			{team.flagImageURL !== undefined && (
				<img className="inline-block w-[180px] h-[180px]" src={team.flagImageURL} alt="flag" />
			)}
			<div className="inline-flex w-[100%] h-[164px] items-center justify-center text-center">
				<div className="Team-title Text-shadow">{team.name}</div>
			</div>
		</div>
	);
};

export default TeamView;
