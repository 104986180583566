import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import OddView from "../components/OddView";
import BackgroundView from "../components/BackgroundView";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

const Template13: React.FC<Props> = (props) => {
  const { template } = props;
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);
  return (
    <div>
      <BackgroundView
        background={template.background}
        title={template.betModel.title}
        subTitle={template.betModel.subTitle}
        titleHighlight={template.betModel.titleHighlight}
        subTitleHighlight={template.betModel.subTitleHighlight}
      >
        <div className="relative flex flex-col justify-center text-center w-[1920px] mt-32">
          <div className="inline-flex justify-center items-center tall:mt-64">
            {template.betModel.odds!!.map((element, index) => {
              return (
                <div
                  key={index}
                  className={`block text-center ${index > 0 && "ml-[300px]"}`}
                >
                  <OddView
                    key={index}
                    odd={element}
                    className={`${
                      index === 0 ? "Odd-extralarge" : "Odd-large mt-10"
                    }`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </BackgroundView>
      <template.background.logo className="absolute w-[200px] bottom-[12px] left-0 right-0 m-auto animate-fade-in-up-logo" />
    </div>
  );
};

export default Template13;
