import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import BackgroundView from "../components/BackgroundView";
import MatchView from "../components/MatchView";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

const Template10: React.FC<Props> = (props) => {
  const { template } = props;
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);
  return (
    <div>
      <BackgroundView
        background={template.background}
        title={template.betModel.title}
        subTitle={template.betModel.subTitle}
        titleHighlight={template.betModel.titleHighlight}
        subTitleHighlight={template.betModel.subTitleHighlight}
      >
        <div className="relative flex flex-col justify-center w-[1920px] mt-16 tall:mt-32">
          {template.betModel.matches!!.map((element, index) => {
            return (
              <div
                key={index}
                className="block mt-2 ml-[124px] h-[78px] animate-fade-in-left-match tall:mt-8"
                style={{ animationDuration: `${1800 + 100 * index}ms` }}
              >
                <MatchView
                  key={index}
                  match={element}
                  timeBeforeOdds={true}
                  className={"Match-normal"}
                />
              </div>
            );
          })}
        </div>
      </BackgroundView>
      <template.background.logo className="absolute w-[200px] right-[92px] top-[92px] animate-fade-in-right" />
    </div>
  );
};

export default Template10;
