import React from "react";
import { BackgroundModel } from "../lib/data";
import MainTitleView from "./MainTitleView";
import SubTitleView from "./SubTitleView";
interface Props {
  background: BackgroundModel;
  title?: string; // TOP 10 CELE MAI PARIATE EVENIMENTE
  titleHighlight?: string; // TOP 10
  subTitle?: string; // STARTING SOON
  subTitleHighlight?: string; // STARTING
  children?: React.ReactNode;
}

const NewBackgroundView: React.FC<Props> = (props) => {
  const {
    background,
    title,
    titleHighlight,
    subTitle,
    subTitleHighlight,
    children,
  } = props;

  return (
    <div className="fixed w-full h-full text-white Fade-in">
      <img
        className="absolute left-0 top-0 w-full h-full object-cover"
        src={background.backgroundImageURL}
        alt="background"
      />

      <div className="absolute left-0 top-0 w-full h-full Background-tint"></div>
      <div className="absolute left-0 top-0 w-full h-full Background-overlay"></div>

      <div className="relative block mt-[60px] w-[1400px] animate-fade-in-left">
        <div className="inline-block ml-[92px] mt-0">
          {title !== undefined && (
            <MainTitleView
              text={title!!}
              textHighlight={titleHighlight === undefined ? "" : titleHighlight}
            />
          )}
          {subTitle !== undefined && (
            <SubTitleView
              text={subTitle!!}
              textHighlight={
                subTitleHighlight === undefined ? "" : subTitleHighlight
              }
            />
          )}
        </div>
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default NewBackgroundView;
