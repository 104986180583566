import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import NewMatchView from "components/NewMatchView";
import NewBackgroundView from "components/NewBackgroundView";
import useAppStore from "store/appstore";

interface Props {
  template: TemplateModel;
  duration?: number;
}

/**
 * Template 17 - A competition table with header, 10 events with 6 odds each
 * @param props
 */
const Template17: React.FC<Props> = (props) => {
  const { template, duration = 15 } = props;
  const changePage = useAppStore((state) => state.changePage);

  useEffect(() => {
    let timeout = setTimeout(changePage, duration * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage, duration]);
  return (
    <div>
      <NewBackgroundView
        background={template.background}
        title={template.betModel.title}
        subTitle={template.betModel.subTitle}
        titleHighlight={template.betModel.titleHighlight}
        subTitleHighlight={template.betModel.subTitleHighlight}
      >
        <div className="relative flex flex-col justify-center w-[1920px] mt-0 tall:mt-32">
          <div className="flex justify-end mx-[94px] h-[64px]">
            {["1", "X", "2", "1X", "X2", "12"].map((odd) => (
              <div
                key={odd}
                className="inline-flex justify-center items-center text-center text-white italic text-[24px] w-[148px] ml-1 font-bold"
              >
                {odd}
              </div>
            ))}{" "}
            <div className="inline-flex w-[120px] ml-1">&nbsp;</div>
          </div>
          {template.betModel.matches!!.map((element, index) => {
            return (
              <div
                key={index}
                className="block mx-[94px] h-[65px] animate-fade-in-left-match tall:mt-8 my-[2px]"
                style={{ animationDuration: `${1800 + 100 * index}ms` }}
              >
                <NewMatchView
                  key={index}
                  match={element}
                  timeBeforeOdds={true}
                  className={"Match-small"}
                />
              </div>
            );
          })}
        </div>
      </NewBackgroundView>
      <template.background.logo className="absolute w-[200px] right-[92px] top-[0px] animate-fade-in-right" />
    </div>
  );
};

export default Template17;
