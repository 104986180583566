import React from "react";
import { MatchModel } from "../lib/data";
import OddView from "./OddView";
import TimeView from "./TimeView";
interface Props {
	match: MatchModel;
	timeBeforeOdds: boolean;
	index?: number;
	className: string;
	suffix?: string;
}

const MatchView: React.FC<Props> = (props) => {
	const { match, timeBeforeOdds, index, className, suffix } = props;

	return (
		<div className={`inline-block w-[100%] ${className}`}>
			{index !== undefined && (
				<div className="float-left text-center Text-yellow Text-shadow Index">{index}</div>
			)}

			<div className="float-left w-[45%] Title Text-shadow">
				<span className={match.team1TextColor}>{match.team1}</span> —{" "}
				<span className={match.team2TextColor}>{match.team2}</span>
			</div>
			{timeBeforeOdds && (
				<div className={`float-left ${className === "Match-small" ? "mr-[24px]" : "mr-12"}`}>
					<TimeView time={match.time!!} singleLine={true} simple={true} small={className === "Match-small"} />
				</div>
			)}

			<div className="float-left">
				{match.odds!!.map((element, index) => {
					return (
						<div
							key={index}
							className={`inline-block text-center ${
								className === "Match-small" ? "w-[130px] ml-4" : "w-[160px] ml-4"
							}`}
						>
							<OddView
								key={index}
								odd={element}
								className={className === "Match-normal" ? "Odd-normal" : "Odd-small"}
							/>
						</div>
					);
				})}
			</div>

			{!timeBeforeOdds && (
				<div className={`float-left ${className === "Match-small" ? "ml-[124px]" : "ml-12"}`}>
					<TimeView time={match.time!!} singleLine={true} simple={true} small={className === "Match-small"} />
				</div>
			)}

			{suffix !== undefined && (
				<div className="float-left text-center Text-yellow Suffix Text-shadow ml-12">{suffix}</div>
			)}
		</div>
	);
};

export default MatchView;
