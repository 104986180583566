import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import BackgroundView from "../components/BackgroundView";
import MatchViewPlus from "../components/MatchViewPlus";
import TimeView from "../components/TimeView";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

const Template16: React.FC<Props> = (props) => {
  const { template } = props;
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);
  return (
    <div className="fixed w-full h-full">
      <BackgroundView
        background={template.background}
        title={template.betModel.title}
        subTitle={""}
        titleHighlight={template.betModel.titleHighlight}
        subTitleHighlight={""}
      >
        <div className="relative flex flex-col justify-center w-[1920px] mt-32">
          <div className="block ml-[132px] tall:mt-[8%]">
            <div className="inline-block w-[100%] Match-normal">
              <div className="float-left w-[40%] invisible">{"a"}</div>
              <div className="float-left text-center w-[160px] mr-20 Plus-text Text-shadow">
                MECI
              </div>
              <div className="float-left text-center w-[160px] mr-20 Plus-text Text-shadow">
                0+4
              </div>
              <div className="float-left text-center w-[160px] mr-20 Plus-text Text-shadow">
                0+7
              </div>
              <div className="float-left text-center w-[160px] mr-20 Plus-text Text-shadow">
                0+11
              </div>
            </div>
          </div>

          <div className="Dashed-border ml-[132px] mr-[132px] mb-16"></div>
          {template.betModel.matches!!.map((element, index) => {
            return (
              <div
                key={index}
                className={`block ml-[132px] ${
                  index > 0 ? "mt-20" : ""
                } animate-fade-in-left-match`}
                style={{ animationDuration: `${1800 + 100 * index}ms` }}
              >
                <MatchViewPlus
                  key={index}
                  match={element}
                  className={"Match-normal"}
                />
              </div>
            );
          })}
          <div className="Dashed-border ml-[132px] w-[36%] mt-16"></div>
        </div>
      </BackgroundView>
      <div className="absolute top-[65px] right-[82px] animate-fade-in-right">
        <TimeView
          time={template.betModel.time!!}
          singleLine={true}
          simple={false}
        />
      </div>
      <template.background.logo className="absolute w-[200px] right-[92px] top-[92px] animate-fade-in-right" />
    </div>
  );
};

export default Template16;
