import { Props } from "lib/data";
import { useCallback, useEffect, useRef, useState } from "react";
import useAppStore from "store/appstore";

const TemplatePicture: React.FC<Props> = ({ mediaURL, duration = 15 }) => {
  const changePage = useAppStore((state) => state.changePage);
  const [cachedURL, setCachedURL] = useState<string | undefined>(undefined);
  let getMediaFromCache = useAppStore((state) => state.getMediaFromCache);
  const timeout = useRef<any | null>(null);

  const getMedia = useCallback(async () => {
    let url = await getMediaFromCache(mediaURL!);
    if (!url) {
      console.log("missing cachedURL, will change page");
      changePage();
    } else {
      console.log(`set cached url ${url}`);
      setCachedURL(url!);
    }
  }, [changePage, getMediaFromCache, setCachedURL, mediaURL]);

  useEffect(() => {
    console.log("Picture setTimeout 10s");
    timeout.current = setTimeout(changePage, duration * 1000);
    return () => {
      console.log("Picture clearing timeout 10s");
      clearTimeout(timeout.current);
    };
  }, [changePage, duration]);

  useEffect(() => {
    console.log("Template Picture useEffect");
    getMedia();
  }, [getMedia]);

  return <div>{cachedURL && <img src={cachedURL} alt="" />}</div>;
};
export default TemplatePicture;
